<template>
  <main class="mainlayout padded explibrary">
    <h2>Experiences</h2>
    <!-- <button @click="debug">debug</button> -->
    <div class="formerror" style="clear:both;" v-if="error">{{error}}</div>
    
    <div class="buttons">
      <input v-model.trim="searchTerm" type="text" style="width: 300px;" :disabled="loading" autocomplete="off" placeholder="Search..." />       
      <button @click="addRow" :disabled="loading">Add New Experience</button><span class="spinner" v-if="loading" style="transform: translateY(-10px);" />
    </div> 
 
    <VueGoodTable
    ref="datatable"
    :columns="columns"
    :rows="$store.state.experiences"
    striped
    styleClass="vgt-table striped"
    :search-options="{
      enabled: false,
      externalQuery: searchTerm
    }"
    :select-options="{ 
      enabled: true,
      selectOnCheckboxOnly: true 
    }">
      <template v-slot:selected-row-actions>  
        <button class="insead autowidth right" :disabled="bulkDeleteDisabled" :title="bulkDeleteDisabled ? 'Some selected experiences are used in sessions, cannot remove.' : ''" @click="bulkDelete">Bulk delete</button>              
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'" class="rowActions">
          <button class="insead autowidth right" @click="editRow(props.row.id)" :disabled="loading">Edit</button>
          <button class="insead autowidth right" @click="editAreas(props.row.id)" :disabled="loading">Areas</button>
          <!-- <button class="insead autowidth right" @click="createMDMjob(props.row.id)" :disabled="loading">{{props.row.fileTransferJobId ? 'Update' : 'Create'}} MDM job</button> -->
          <button class="insead autowidth right" :disabled="loading || props.row.count > 0" :title="props.row.count > 0 ? 'Used in sessions, cannot remove.' : ''" @click="deleteRow(props.row.id)">Delete</button>
        </span>      
        <span v-else-if="props.column.field == 'updated'">
          {{ props.row.updated ? toTimeZone(new Date(props.row.updated), $store.getters.timeZone).toLocaleString() : '-' }}
        </span>
        <span v-else-if="props.column.field == 'created'">
          {{ toTimeZone(new Date(props.row.created), $store.getters.timeZone).toLocaleString() }}
        </span>
        <span v-else-if="props.column.field == 'fileTransferJobCreated'">
          {{ toTimeZone(new Date(props.row.fileTransferJobCreated), $store.getters.timeZone).toLocaleString() }}
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </VueGoodTable>

    <span class="spinner relative" v-if="loading" />
    

    <Modal2 v-if="showModal" @close="showModal = false; error = ''; s3files = []; warnings = 0; tempExperience = undefined; tempStructure = undefined;" class="experiencemodal center">      
      <h3 slot="header" v-if="tempExperience.id">Edit Experience #{{tempExperience.id}}</h3>      
      <h3 slot="header" v-else>Add new experience</h3>
      <div slot="body" style="display:flex; gap: 20px;">        
        <div style="max-width:400px;">
          <label required>Name</label>
          <input v-model.trim="tempExperience.name" type="text" placeholder="experience name"> 
                  
          <label>Content item ID in Drupal CMS</label>
          <input v-model.number="tempExperience.cmsId" type="number" placeholder="experience Id in CMS system">

          <label>Web Content Root Path => this will use <b>Backblaze</b>!</label>
          <input v-model.trim="tempExperience.webRoot" type="text" placeholder="Content path with trailing slash">

          <label required>Structure</label>
          <textarea class="jsonarea" v-model="tempExperience.structure" placeholder="json structure" style="resize:vertical;"/> 
          <div v-if="testExperienceJsonInvalid" style="color: red;">Could not parse JSON, please check syntax.</div> 
          <div v-if="idStats.segmentDuplicates.length > 0" style="color: red;">Duplicate segment Ids found: {{idStats.segmentDuplicates.map(x => x.id).join(', ')}}</div>
          <div v-if="idStats.otherDuplicates.length > 0" style="color: orange;">Duplicate other item Ids found: {{idStats.otherDuplicates.map(x => x.id).join(', ')}}</div>
          <button @click="printIdStats" v-if="idStats.segmentDuplicates.length > 0 || idStats.otherDuplicates.length > 0">Click here for details in dev tools Console</button><br>

          <!-- <div class="schemaerrors">
            <button @click="validateSchema">Validate (experimental)</button>
            <div v-for="(error, index) in schemaValidatonErrors" :key="`error-${index}`">
              Path: {{error.instancePath ? error.instancePath : "/"}}, Keyword: {{error.keyword}}, Message: {{error.message}}
            </div>
          </div>           -->
          <label>Icon thumbnail:</label>
          <div style="clear:both; width: 100px;">
            <img :src="(tempExperience.webRoot ? backblazeBlobRoot : blobRoot) + iconUrl" style="width:100px; border-radius: 5px;" />
          </div>
          <label>Organization <span style="color:#aaa;">(none selected means available for everyone)</span></label>
          <div>
            <VueGoodTable
            style="clear:both;"
            ref="orgdatatable"
            :columns="orgColumns"
            :rows="organizations"        
            styleClass="vgt-table"            
            :fixed-header="false"
            :select-options="{ 
              enabled: true
            }">                            
            </VueGoodTable>
          </div>
        </div>
        <div style="min-width:200px; padding-left:20px;border-left:1px dashed #777;white-space:nowrap;">
          <label>File list of experience last updated UTC: {{tempExperience.fileListDate ? tempExperience.fileListDate : '-'}}</label>
          <br>
          <table>
            <tr>
              <th>File path</th>
              <th>Size [{{this.tempExperience.fileList ? humanFileSize(this.tempExperience.fileList.reduce(( previousValue, currentValue ) => previousValue + currentValue.size, 0)) : '-'}}]</th>
              <th>Size [S3] [{{humanFileSize(this.s3files.reduce(( previousValue, currentValue ) => previousValue + currentValue.size, 0))}}]</th>
              <th>Last Modified UTC</th>
              <th>Last Modified [S3] UTC</th>
              <th>eTag</th>
              <th>eTag [S3]</th>
            </tr>
            <tr v-for="file in mergedFiles" :key="file.key" :class="{warning: file.warning, deleted: file.current && !file.s3, new: !file.current && file.s3, modified: file.current && file.s3 && (file.current.eTag != file.s3.eTag) }">
              <td>
                <span>{{file.key}}</span>
              </td>
              <td :title="file.current ? file.current.size :''">{{file.current ? humanFileSize(file.current.size) : ''}}</td>
              <td :title="file.s3 ? file.s3.size : ''">{{file.s3 ? humanFileSize(file.s3.size) : ''}}</td>
              <td>{{file.current ? file.current.lastModified : ''}}</td>
              <td>{{file.s3 ? file.s3.lastModified : ''}}</td>
              <td>{{file.current ? file.current.eTag : ''}}</td>
              <td>{{file.s3 ? file.s3.eTag : ''}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div slot="footer">
        <div style="padding-bottom:10px;">          
          <div v-if="filesWithWarnings > 0 && warnings >= 0">
            <div style="color: orange; font-size: 20px; padding: 10px 0;">{{filesWithWarnings}} filenames contain whitespaces, multiple dots, or special chars other than "<b style="font-size:20px;">_</b>" and "<b style="font-size:20px;">-</b>"</div>
            <div v-if="warnings >= 1" style="color: orange; font-size: 20px; padding: 10px 0;">Are you sure about this? Does it worth taking such heavy <b><i>responsibility</i></b> for your actions?</div>
            <div v-if="warnings >= 2" style="color: orange; font-size: 20px; padding: 10px 0;">Having spaces or special chars in filenames might break in the world of web (think of 42Gears). It is really not a good idea to have such filenames. You still sure?</div>
            <div v-if="warnings >= 3" style="color: orange; font-size: 20px; padding: 10px 0;">You are right, this thing was made on purpose to highlight the importance of web-safe paths and filenames.</div>
            <button class="full" v-if="s3files.length > 0" @click="bumpWarnings" id="s3warningsbutton" :disabled="warningsButtonDisabled">
              {{warningTexts[warnings]}}
            </button>
          </div>
          <div v-if="s3files.length > 0">
            <span class="deleted">{{filesDeleted}}</span> deleted, <span class="new">{{filesAdded}}</span> added, <span class="modified">{{filesModified}}</span> modified
          </div>
          <button v-if="s3files.length == 0" class="full" :disabled="!webContentRootPath" @click="getS3files">Fetch and validate S3 files first to proceed</button>
        </div>
        <button class="full" v-if="tempExperience.id" :class="{disabled: modalButtonDisabled}" @click="updateExperience" :disabled="modalButtonDisabled">Update</button>
        <button class="full" v-else :class="{disabled: modalButtonDisabled}" @click="addExperience" :disabled="modalButtonDisabled">Add</button>        
      </div>
    </Modal2>   

    <Modal2 v-if="showAreasModal" @close="showAreasModal = false;" class="center">      
      <h3 slot="header">Edit heatmap areas for experience</h3>
      <div slot="body">
        <div v-if="areas.length > 0">
          <div v-for="area in areas" :key="area.label" style="margin:5px; border-bottom:1px dashed grey; cursor:pointer;" @click="areaSelected(area)">
            <div>segmentId: {{area.segmentId}}, itemId: {{area.itemId}} <button style="float:right;" @click.prevent="deleteArea(area)">delete</button></div>            
          </div></div>
        <div v-else>
          0 areas so far          
        </div>

        <hr style="margin-top:40px;">
        <div>Add or Update area for item:</div>
        <br>
        segmentId: 
        <input type="number" style="width:120px;" v-model="segmentId" />
        itemId: 
        <input type="number" style="width:120px;" v-model="itemId" />
        <br>
        <br>
        <textarea v-model.trim="areasJson" style="min-height:100px;"/>
        <button class="insead" @click="addOrUpdateArea" :disabled="loading || !segmentId || !itemId || !areasJson">Add or Update area</button>
      </div>
      <div slot="footer">       
        <!-- <button class="right" @click="closeSessionModal = false; closeSession()">Close session</button>   
        <button class="right white" @click="closeSessionModal = false">Cancel</button>             -->
      </div>
    </Modal2>

    <Snackbar ref="snackbar" /> 
  </main>
</template>

<script>
import axios from 'axios';
import { VueGoodTable } from 'vue-good-table'
import Modal2 from '@/components/Modal2.vue'
import Ajv from "ajv"
import ajvErrors from 'ajv-errors'
import Snackbar from '@/components/Snackbar.vue'
import { humanFileSize, formatDate, toTimeZone } from '@/utils.js'
import _ from 'lodash'

const schema = 
{
  "$schema": "http://json-schema.org/draft-07/schema",
  "title": "Experience",
  "description": "This is the root element of the JSON.", 
  "type": "object",
  "required": [
    "webContentRootPath",
    "list"
  ],
  "allOf": [{ "$ref": "#/$defs/list" }],

  "properties": {
    "webContentRootPath": {
      "description": "The root of where the actual content files exist, for example \"experiences/somefolder/mycustomplace/\". The trailing / is important, and no whitespaces allowed.",
      "type": "string",
      "pattern": "^([a-zA-Z0-9_-]+[/])+$"
    },
    "type": {
      "const": "list",
      "description": "The type of the root element always has to be \"list\"."      
    }    
  },
  "$defs": {
    "list": {
      "title": "List",
      "description": "This is used to represent generic content folders (or as they are referred to within experiences: chapters).", 
      "type": "object",
      "required": [
        "type",
        "txt1",
        "list"
      ],
      "properties": {
        "type": {
          "const": "list",
          "description": "Always \"list\" that indicates this is just generic content structuring element."      
        },   
        "txt1": {
          "description": "Used to display folder name in the dashboard, should not be empty string.",
          "type": "string",
          "pattern": "\\S+(.|\\n)*",
          "errorMessage": {            
            "pattern": "Cannot be empty string"
          }          
        },
        "icon": {
          "description": "Url of the folder thumbnail image (will be used on the dashboard), no whitespaces allowed.",
          "type": "string",
          "pattern": "^([\\w-]+[/]?)+[.][\\w]+$"
        },
        "list": {
          "description": "Array of child content elements. Can contain 'list', 'conditional' and 'seg' elements.",
          "type": "array",
          "items": {
            // "anyOf": [
            //   { "$ref": "#/$defs/list" },
            //   { "$ref": "#/$defs/seg" }//,
            //   //{ "$ref": "#/$defs/conditional" }
            // ]
            "required": [ "type" ],
            "properties": {
              "type": { 
                "description": "Items in a 'list' can be: 'list', 'conditional', 'seg'",
                "enum": [ "list", "conditional", "seg"] 
              }
            },
            "allOf": [
              {
                "if": {
                  "properties": { "type": { "const": "list" } }
                },
                "then": {
                  "$ref": "#/$defs/list"
                }
              },
              {
                "if": {
                  "properties": { "type": { "const": "seg" } }
                },
                "then": {
                  "$ref": "#/$defs/seg"
                }
              },
              {
                "if": {
                  "properties": { "type": { "const": "conditional" } }
                },
                "then": {
                  "$ref": "#/$defs/conditional"
                }
              }
            ]
          },
          "uniqueItemProperties": ["id"],  // AJV specific, not in standard json schema
          "errorMessage": {                // AJV specific, not in standard json schema
            "uniqueItemProperties": "List items must have unique ids."
          } 
        }
      }
    },
    "conditional": {
      "title": "Conditional",
      "description": "This is used to represent a parent object containing child segments as conditions.", 
      "type": "object",
      "required": [
        "type",
        "txt1",
        "txt2",
        "id",
        "list"
      ],
      "properties": {
        "type": {
          "const": "conditional",
          "description": "Always \"conditional\"."      
        },   
        "id": {
          "type": "number",
          "description": "Unique identifier of the conditional segment, this is how server and device mutually talk about the same content piece. Will be automatically generated once content management will happen in the authoring tool."      
        },  
        "txt1": {
          "description": "Used to display conditional segment’s name in the dashboard.",
          "type": "string",
          "pattern": "\\S+(.|\\n)*",
          "errorMessage": {            
            "pattern": "Cannot be empty string"
          }          
        },
        "txt2": {
          "description": "Summary description of the conditional segment, displayed in the dashboard.",
          "type": "string",
          "pattern": "\\S+(.|\\n)*",
          "errorMessage": {            
            "pattern": "Cannot be empty string"
          }          
        },
        "icon": {
          "description": "Url of the conditional segment thumbnail image, no whitespaces allowed.",
          "type": "string",
          "pattern": "^([\\w-]+[/]?)+[.][\\w]+$"
        },
        "list": {
          "description": "Array of child content elements. Can contain seg elements, and each of those child elements must have the 'condition' property set.",
          "type": "array",
          "items": {
            "allOf": [{ "$ref": "#/$defs/segCondition" }]
          },
          "minItems": 2,
          "uniqueItemProperties": ["id"],  // AJV specific, not in standard json schema 
          "errorMessage": {            
            "minItems": "At least two conditional segments needed to make sense. Consider using a single non-conditional segment instead.",
            "uniqueItemProperties": "Conditions must have unique ids."
          }                   
        }
      }
    },
    "segCommon": { 
      "title": "Segment Common parts",
      "description": "Shared parts of a segment (used in seg and segCondition)", 
      "type": "object",
      "required": [
        "type",
        "txt1",
        "txt2",
        "id",
        "segJson"
      ],
      "properties": {
        "type": {
          "const": "seg",
          "description": "Always \"seg\"."      
        },   
        "id": {
          "type": "number",
          "description": "Unique identifier of the segment, this is how server and device mutually talk about the same content piece. Will be automatically generated once content management will happen in the authoring tool."      
        },  
        "txt1": {
          "description": "Used to display segment/condition name in the dashboard.",
          "type": "string",
          "pattern": "\\S+(.|\\n)*",
          "errorMessage": {            
            "pattern": "Cannot be empty string"
          }          
        },
        "txt2": {
          "description": "Detailed description of the segment, displayed in the dashboard.",
          "type": "string",
          "pattern": "\\S+(.|\\n)*",
          "errorMessage": {            
            "pattern": "Cannot be empty string"
          }          
        },
        "segJson": {
          "description": "This is the JSON defining the actual segment structure. ",
          "type": "object",             
          "allOf": [{ "$ref": "#/$defs/segJson" }]   
        },
        "icon": {
          "description": "Url of the segment thumbnail image, no whitespaces allowed.",
          "type": "string",
          "pattern": "^([\\w-]+[/]?)+[.][\\w]+$"
        }           
        
      }//,
      // // "dependentRequired": {
      // //   "requirements": ["condition"]
      // // }
      // "if": {
      //   "required": ["requirements"]
      // },
      // "then": {
      //   "required": ["condition"],
      //   "errorMessage": {            
      //     "required": "Allocation requirements can only be defined for conditions. Single segments will always be allocated for everyone."
      //   } 
      // }
    },
    "seg": { 
      "$ref": "#/$defs/segCommon",
      "title": "Segment",
      "description": "This is used to represent an undividable unit of content for playback.", 
      "properties": { 
        "requirements": { 
          "not": {},
          "errorMessage": "Allocation requirements can only be defined for conditions. Single segments will always be allocated for everyone."
        }
      }
    },
    "segCondition": {
      "$ref": "#/$defs/segCommon",
      "title": "Conditional Segment",
      "description": "Same as the Segment (seg) type, but this must be used inside a Conditional. Extended with the required 'condition' property.", 
      "type": "object",
      "required": [
        "condition"
      ],
      //"allOf": [{ "$ref": "#/$defs/segCommon" }], // this does not get picked up correctly by vs code intellisense, and just shows the common parts description, so using #ref instead to inline the common parts
      "properties": {        
        "condition": {
          "description": "Dashboard uses this to display the condition label together with the name from txt1. (this is what previously was the 'letter' field in the json for the conditions, like 'A', 'B', 'C')",
          "type": "string",
          "pattern": "\\S+.*",
          "errorMessage": {            
            "pattern": "Cannot be empty string, cannot contain newline characters."
          }          
        },
        "requirements": {
          "description": "If present, the conditions to play cannot be selected manually, and will be allocated automatically to users in the session who have the matching key-value pairs in their Metadata. (Dictionary<string,string> type in .net API)",
          "type": "object",
          "additionalProperties": { "type": "string" }          
        }
      }
    },
    "segJson": {
      "title": "Segment Details",
      "description": "This is the json defining the actual segment structure.", 
      "type": "object",
      "required": [
        "items"
      ],      
      "properties": {        
        "items": {
          "description": "Array of the items in this segment (text, videos, questions, etc...)",
          "type": "array",
          "items": {
            //"allOf": [{ "$ref": "#/$defs/segItem" }]
          }
          // TODO minimum number of elements? Empty array not allowed, at least one item needed to make sense.
        }
      }
    }
  }
}

const ajv = new Ajv({
  allErrors: true,
  keywords: [
    require("ajv-keywords/dist/definitions/uniqueItemProperties")()
  ]
}) // options can be passed, e.g. {allErrors: true}
ajvErrors(ajv)

//const filenameWarningRegex = new RegExp("[^A-Za-z0-9\\-/\\._]+")

let validFileNameRegex = /^([a-zA-Z0-9_-]+\/)+[a-zA-Z0-9_-]+\.[a-zA-Z0-9]+$/



export default {
  name: 'Library',
  data: function(){
    return {
      orgColumns: [
        {
          label: 'ID',
          field: 'id',
          type: 'number',
        },
        {
          label: 'Name',
          field: 'name'
        }
      ],
      columns: [
        {
          label: 'Id',
          field: 'id',
          type: 'number',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Updated',
          field: 'updated',
        },
        {
          label: 'Created',
          field: 'created',
        }, 
        {
          label: 'Drupal Id',
          field: 'cmsId',
        }, 
        {
          label: 'Session count',
          field: 'count',
          type: 'number',
        },     
        {
          label: 'MDM Job',
          field: 'fileTransferJobId'
        },
        {
          label: 'MDM Job created',
          field: 'fileTransferJobCreated'
        },   
        {
          label: 'Actions',
          field: 'actions'
        }        
      ],      
      blobRoot: process.env.VUE_APP_PUBLIC_BLOB,
      backblazeBlobRoot: process.env.VUE_APP_PUBLIC_BLOB_BACKBLAZE,
      organizations: [],
      loading: false,
      error: undefined,
      searchTerm: undefined,
      showModal: false,   
      showAreasModal: false,   
      tempExperience: {
        id: undefined,
        name: undefined,
        structure: undefined,
        cmsId: undefined,
        webRoot: undefined
      },
      schemaValidatonErrors: undefined,
      areasJson: undefined,
      segmentId: undefined,
      itemId: undefined,
      areas: [],
      s3files: [],
      tempStructure: undefined,
      warnings: 0,
      warningTexts: [
        'Ignore the warnings and proceed',
        'I\'m sure to ignore these and proceed despite the 2nd warning',
        'I\'m feeling lucky and taking my chances',
        'Click this one more time in the name of web-safe paths and filenames'
      ],
      warningsButtonDisabled: false,
      objectsWithIds: []
      // ids: new Set(),
      // duplicateIds: new Set()
    }
  },
  computed: {
    bulkDeleteDisabled(){
      return this.loading || !this.selectedExperiences.length || this.selectedExperiences.filter(exp => exp.count > 0).length > 0
    },  
    idStats(){
      let segmentItems = this.objectsWithIds.filter(x => ['seg','conditional'].includes(x.type))
      let otherItems = this.objectsWithIds.filter(x => !['seg','conditional'].includes(x.type))

      let segmentDuplicates = _.chain(segmentItems).groupBy('id').map((value, key) => ({ id: key, items: value })).value().filter(x => x.items.length > 1)
      let otherDuplicates = _.chain(otherItems).groupBy('id').map((value, key) => ({ id: key, items: value })).value().filter(x => x.items.length > 1)

      return {segmentDuplicates, otherDuplicates}
    },
    testExperienceJsonInvalid(){
      try{
        // eslint-disable-next-line  
        this.objectsWithIds = []
        // this.ids.clear()
        // this.duplicateIds.clear()
        // eslint-disable-next-line        
        this.tempStructure = JSON.parse(this.tempExperience.structure)               
        this.scan(this.tempStructure) 
        return false
      }
      catch{
        //console.error(ex)
        return true
      }
    },
    selectedExperiences(){
      return this.$refs.datatable.selectedRows;
    },
    modalButtonDisabled(){
      return this.loading || this.testExperienceJsonInvalid  || !this.tempExperience.name || (this.warnings >= 0 && this.filesWithWarnings > 0) || this.s3files.length == 0 || this.idStats.segmentDuplicates.length > 0
    },
    webContentRootPath(){
      return !this.tempExperience?.webRoot ? this.tempStructure?.webContentRootPath : this.tempExperience.webRoot
    },
    iconUrl(){
      return this.webContentRootPath + this.tempStructure?.icon
    },
    filesWithWarnings(){
      return this.mergedFiles.filter(f => f.warning).length
    },    
    filesDeleted(){
      return this.mergedFiles.filter(f => f.current && !f.s3).length
    },  
    filesAdded(){
      return this.mergedFiles.filter(f => !f.current && f.s3).length
    }, 
    filesModified(){
      return this.mergedFiles.filter(f => f.current && f.s3 && (f.current.eTag != f.s3.eTag)).length
    }, 
    mergedFiles(){
      return _.union(this.tempExperience.fileList?.map(f => f.key), this.s3files?.map(f => f.key)).map(key => ({        
          key: key,
          current: this.tempExperience.fileList?.find(f => f.key == key),
          s3: this.s3files?.find(f => f.key == key),
          warning: this.filenameWarning(key)        
      }))
    }
  },
  methods: { 
    // debug(){
    //   // console.log(this.filenameWarning("yolo.jp"))
    //   // this.s3files.forEach(f => {
    //   //   if(!/^([\w-]+[/]?)+[.][\w]+$/.test(f.key))
    //   //     console.log(f.key)
    //   // })
    // },
    printIdStats(){
      console.log(JSON.parse(JSON.stringify(this.idStats)))
    },
    scan(obj) {
      var k;
      if (obj instanceof Object) {
          for (k in obj){
              // eslint-disable-next-line
              if (obj.hasOwnProperty(k)){
                if(k == 'id'){
                  // console.log(obj['type'])
                  // console.log(obj)
                  this.objectsWithIds.push(obj)


                  // if(this.ids.has(obj[k]))
                  //   this.duplicateIds.add(obj[k])
                  // else
                  //   this.ids.add(obj[k])

                  
                }
                //recursive call to scan property
                this.scan( obj[k] )
              }                
          }
      } else {
          //obj is not an instance of Object so obj here is a value
      }
    },
    toTimeZone(d, tz){ return toTimeZone(d,tz) }, 
    formatDate(d, tz){ return formatDate(d, tz) },
    async getOrganizations(){
      let resp = await axios({ url: "organizations" })
      this.organizations = resp.data
    },
    bumpWarnings(){
      if(this.warnings == 1 /*3*/)
      {
        this.warnings = -1
        return
      }
      this.warnings++; 
      this.warningsButtonDisabled = true;
      setTimeout(() => this.warningsButtonDisabled = false, 2000*this.warnings)
    },
    humanFileSize(size){
      return humanFileSize(size)
    },
    filenameWarning(filename){
      return !validFileNameRegex.test(filename)
    },  
    async getS3files(){
      this.s3files = (await axios.get(`experiences/${this.tempExperience?.id ?? -1}/s3files?webroot=${encodeURIComponent(this.webContentRootPath)}&backblaze=${this.tempExperience?.webRoot ? true : false}`)).data      
    },
    validateSchema(){
      const schemaValidator = ajv.compile(schema) // TODO after feature won't be just experimental, compile only once, not with every button click
      const isValid = schemaValidator(JSON.parse(this.tempExperience.structure))
      this.schemaValidatonErrors = schemaValidator.errors
      if (!isValid) 
        console.log(schemaValidator.errors)      
    },
    async bulkDelete(){
      if(confirm(`Delete the ${this.selectedExperiences.length} selected experiences?`)){
        let forDeletion = this.selectedExperiences.map(s => s.id)
        await axios({ url: `experiences`, data: forDeletion, method: 'DELETE' })        
        this.$store.state.experiences = this.$store.state.experiences.filter(s => !forDeletion.includes(s.id))
      }
    },
    async deleteRow(id){
      if(confirm(`Delete experience with id ${id}?`)){
        await axios({ url: `experiences`, data: [id], method: 'DELETE' })        
        this.$store.state.experiences = this.$store.state.experiences.filter(s => s.id != id)
      }
    },
    async editRow(id){      
      this.tempExperience = JSON.parse(JSON.stringify(this.$store.state.experiences.find(s => s.id == id))) 
      let experienceOrganizations = await axios.get(`experiences/${id}/organizations`)
      this.organizations.forEach(org => {
        this.$set(org, 'vgtSelected', experienceOrganizations.data.includes(org.id) ? true : false)
      })
      this.showModal = true
    },
    async editAreas(id){  
      this.tempExperience = JSON.parse(JSON.stringify(this.$store.state.experiences.find(s => s.id == id)))     
      let url = `analytics/metadata?experienceId=${this.tempExperience.id}`      
      let resp = await axios({ url: url, method: 'GET' }) 
      this.areas = resp.data
      this.segmentId = undefined
      this.itemId = undefined
      this.areasJson = undefined
      this.showAreasModal = true
    },
    areaSelected(area){
      this.segmentId = area.segmentId
      this.itemId = area.itemId
      this.areasJson = area.areasJson
    },
    async addOrUpdateArea(){
      this.error = undefined;
      this.$nprogress.start();
      this.loading = true;
      try{
        let tmp = JSON.parse(this.areasJson)
        tmp.forEach(a => {
          a.liveCoverage = 0,
          a.aggregatedCoverage = 0
          a.checked = true 
        })
        let url = `analytics/metadata?experienceId=${this.tempExperience.id}&segmentId=${this.segmentId}&itemId=${this.itemId}`    
        let data = JSON.stringify(tmp)  
        // eslint-disable-next-line 
        await axios.post(url, data
        , {
          headers: {
              'Content-Type': 'application/json',
          }
        }
        )   
        this.$refs.snackbar.show('Areas saved on server.')
        await this.editAreas(this.tempExperience.id)
      }
      catch(err){
        console.log("Error when updating experience: " + err.response);
        this.error = err?.response?.data ?? err
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }  
    },
    async deleteArea(area){
      this.error = undefined;
      this.$nprogress.start();
      this.loading = true;
      try{        
        let url = `analytics/metadata?experienceId=${this.tempExperience.id}&segmentId=${area.segmentId}&itemId=${area.itemId}`
        // eslint-disable-next-line 
        await axios.delete(url)
        this.$refs.snackbar.show('Areas for item deleted on server.')
        await this.editAreas(this.tempExperience.id)
      }
      catch(err){
        console.log("Error when updating experience: " + err.response);
        this.error = err?.response?.data ?? err
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      } 
    },
    async addRow(){      
      this.tempExperience = {
        id: undefined,
        name: undefined,
        structure: undefined,
        cmsId: undefined,
        icon: undefined
      } 
      this.showModal = true
    },
    async updateExperience(){    
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        
        this.tempExperience.cmsId = this.tempExperience.cmsId ? this.tempExperience.cmsId : null
        this.tempExperience.organizationIds = this.$refs.orgdatatable.selectedRows.map(o => o.id)
        this.tempExperience.icon = this.iconUrl
        let resp = await axios({ url: `experiences/${this.tempExperience.id}`, data: this.tempExperience, method: "POST" })    
        //console.log(resp.data)      
        let tmpIdx = this.$store.state.experiences.findIndex(s => s.id == this.tempExperience.id)      
        this.$store.state.experiences.splice(tmpIdx, 1, resp.data)
        this.$store.state.experiences[tmpIdx].count = this.tempExperience.count

        this.showModal = false
        this.error = '' 
        this.s3files = [] 
        this.warnings = 0
        this.tempExperience = undefined
        this.tempStructure = undefined
      }
      catch(err){
        console.log("Error when updating experience: " + err.response);
        this.error = err?.response?.data ?? err
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }        
    },
    async addExperience(){    
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        
        this.tempExperience.cmsId = this.tempExperience.cmsId ? this.tempExperience.cmsId : null
        this.tempExperience.organizationIds = this.$refs.orgdatatable.selectedRows.map(o => o.id)
        this.tempExperience.icon = this.iconUrl
        let resp = await axios({ url: `experiences`, data: this.tempExperience, method: "POST" })    
        //console.log(resp.data)
        this.$store.state.experiences.push(resp.data)
        
        this.showModal = false
        this.error = '' 
        this.s3files = [] 
        this.warnings = 0
        this.tempExperience = undefined
        this.tempStructure = undefined
      }
      catch(err){
        console.log("Error when adding new experience: " + err.response);
        this.error = err?.response?.data ?? err
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }        
    },
    // async createMDMjob(id){
    //   try{
    //     this.error = undefined
    //     this.$nprogress.start()
    //     this.loading = true
    //     let resp = await axios.post(`experiences/${id}/createmdmjob`) 
    //     let indexOfItem = this.experiences.findIndex(e => e.id == id)      
    //     this.$set(this.experiences, indexOfItem, resp.data.experience)
    //     this.$refs.snackbar.show('File transfer job successfully created in both Avris and Insead MDM accounts', 5000)   
    //   }
    //   catch(err){
    //     this.error = err?.response?.data ?? err
    //   }
    //   finally{
    //     this.loading = false
    //     this.$nprogress.done()
    //   }  
    // },
  },  
  components: {
    VueGoodTable,
    Modal2,
    Snackbar
  },
  async mounted() {
    try{
      this.$nprogress.start()
      this.loading = true
      await this.getOrganizations()    
    }
    finally{
      this.loading = false
      this.$nprogress.done()
      this.$store.state.showLoader = false
    }
  },
  created() {
  }
}
</script>

<style lang="scss">

main.explibrary{
  display: block;

  h2{
    margin: 0 0 10px 0;
    font-size: 20px;    
    font-weight: bold;
  }

  .experiencemodal.center{
    .modal2-container{
      max-width: unset;
      width: 90%;
    }    
  }

  th{
    text-align: left;
  }
  .zip{
    color: rgb(196, 196, 196);
  }
  .deleted{
    background-color:lightcoral;
  }
  .new{
    background-color:lightgreen;
  }
  .modified{
    background-color:yellow;
  }
  .warning{    
    td:first-child{
      background-color: orange;
    }
  }

  .buttons{
    display:flex; 
    justify-content: space-between; 
    align-items: flex-end; 
    margin-bottom: 14px;

    input{
      width: 300px;
      background-image: url(../assets/magnifier.svg);
      background-position: calc(100% - 6px) center;
      background-repeat: no-repeat;
      padding-right: 40px;
    }
  }

  .schemaerrors{
    font-size: 12px;
  }

  .rowActions{
    display: flex;
    opacity: 0.0;
  }

  .vgt-table tr:hover .rowActions{
    opacity: 1;
  }

  .modal2-body textarea.jsonarea{
    min-height: 100px;
    resize: vertical;
  }
}



</style>
